import React from 'react'
import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom'
import { useState,  useEffect } from "react";
import $ from "jquery";
import styles from "../App.css";
import logo from './fox.png';
import iframe from './iframe';
 
function LoginPage () {
    const [username, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const [result, setResult] = useState("false");
    const [warning, setWarning] = useState("");
    const [free, setFree] = useState("0");
    const [max, setMAx] = useState("0");
    const [word, updateWord] = useState(0);
    const navigate = useNavigate();
    const handleChange = (e) => {
        setUserName(e.target.value1);
        setPassword(e.target.value2);
    };
    const words = ["word1", "word2", "word3", "word4"];
    const handleSubmit = (e) => {
        document.body.style.zoom = "100%";
        e.preventDefault();
        if (result=="false") {
            setWarning("No free slots left, please try again later");
        }
        else {
            setWarning("");
            const form = $(e.target);
            $.ajax({
                type: "POST",
                url: 'https://whitefox.zapto.org/server.php',
                data: form.serialize(),
                success(response) {
                    //setWarning(response);
                    const data = JSON.parse(response)
                    if (data.state=="0")
                    {
                        setWarning("Wrog username or password");
                    }
                    else if (data.state=="4")
                    {
                        setWarning("User allready logged in, please wait");
                    }
                    else {
                        setWarning(data.sesion);
                        iframe.bla= data.sesion;
                        navigate("/iframe", {state:{id:data.sesion}});
                        
                    }
                },
            });
        }
    };
    const atOpenPage= () => {
        $.ajax({  
            type: 'POST',  
            url: 'https://whitefox.zapto.org/server.php', 
            data: { data: "getFreeSlots" },
            
            success: function(response) {
                const data = JSON.parse(response)
                setFree(data.free);
                setMAx(data.max);
                if (0==data.free) setResult("false");
                else setResult("true");
                
            }
        });
    };
    useEffect(() => {

        let nextWord;
    
        // logic stays the same
        if (word === words.length - 1) {
          nextWord = 0;
        } else {
          nextWord = word + 1;
        }
        atOpenPage();
        // update the word
        setTimeout(() => updateWord(nextWord), 1000);
      }, [word]);
      
    return (
        <div className="App" >
        <br></br>
        <label className="slotText" htmlFor="slotText">Freeslot {free} of {max}</label>
        <br></br>
         <img src={logo} alt="Logo" width="192" height="192" />
         <br></br>
          <form
            
              action="https://whitefox.zapto.org/server.php"
              method="post"
              onSubmit={(event) => handleSubmit(event)}
          >
              <label className="username" htmlFor="username">Username: </label>
              <input
                  className="input"
                  type="text"
                  id="username"
                  name="username"
                  value1={username}
                  
              />
              <br></br>
              <br></br>
              <label className="password" htmlFor="password"style={styles.text}>Password: </label>
              <input
                  className="input"
                  type="password"
                  id="password"
                  name="password"
                  value2={password}
                  
              />
              <br />
              <br></br>
              <button type="submit" className="submit" >Submit</button>
          </form>
          <br></br>
          <label className="username">{warning} </label>
      </div>
    )
}
 
export default LoginPage
