import './App.css';
import { Link, Route,  Routes} from 'react-router-dom';
import LoginPage from './components/login';
import IframePage from './components/iframe';

 
function App() {
    return (
        <div className="App">
           <Routes>
                <Route path="/"   element={<LoginPage />} />
                <Route path="/iframe"  element={<IframePage />} />
            </Routes>
        </div>
    );
}
 
export default App;
