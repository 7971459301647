import React from 'react';
import { useNavigate } from "react-router-dom";
import { useState,  useEffect } from "react";
import $ from "jquery";
import "../App.css";
import {useLocation} from 'react-router-dom';


function IframePage (){
    let session ="";
    const location = useLocation();
    const navigate = useNavigate();
    if (location.state!= null)  session=location.state.id;
    const words = ["word1", "word2", "word3", "word4"];

    const [url, updateUrl] = useState("");
    const [word, updateWord] = useState(0);

    function getData(url) {
        $.ajax({  
            type: 'POST',  
            url: url, 
            data: { data: "chekIfLogged", session: session},
            success: function(response) {
                const data = JSON.parse(response)
                if (data.state==0) navigate("/");
                else {
                    updateUrl(data.url);
                }
            }
        });
    };
    function areYouThere() {
        
        //if (location.state== null) return;
        $.ajax({  
            type: 'POST',  
            url: 'https://whitefox.zapto.org/server.php', 
            data: { data: "areYouThere", session: session},
            success: function(response) {
              const data = JSON.parse(response)
               if (data.tunnel==0) navigate("/");
            }
        });
    };


     useEffect(() => {
            getData('https://whitefox.zapto.org/server.php');
      });

      useEffect(() => {

        let nextWord;
    
        // logic stays the same
        if (word === words.length - 1) {
          nextWord = 0;
        } else {
          nextWord = word + 1;
        }
        areYouThere();
        // update the word
        setTimeout(() => updateWord(nextWord), 2000);
      }, [word]);

    return (
        <div className="App">
        <iframe className="App" src={url}></iframe>
      </div>
    )
}
 
export default IframePage